import { useTranslation } from '@pancakeswap/localization'
import { Flex, Text } from '@pancakeswap/uikit'
import { ChainId, defaultChainId } from '@pancakeswap/chains'
import { StyledPriceChart } from './styles'


const tokens: { [key: string]: string } = {
  [ChainId.PULSECHAIN]: 'PLS',
  [ChainId.UNICHAIN_SEPOLIA]: 'ETH',
  [ChainId.WORLDCHAIN]: 'ETH',
  [ChainId.ZEUSCHAIN]: 'ZEUS',
  [ChainId.APECHAIN]: 'APE',
  [ChainId.MORPHCHAIN]: 'ETH',
  [ChainId.INKCHAIN]: 'ETH',
  [ChainId.BASECHAIN]: 'ETH',
  [ChainId.SONIC]: 'S'
};
interface BnbWbnbNoticeProps {
  isDark: boolean
  isChartExpanded: boolean
  tokenChainId:number
}

const BnbWbnbNotice: React.FC<React.PropsWithChildren<BnbWbnbNoticeProps>> = ({ isDark, isChartExpanded,tokenChainId= defaultChainId }) => {
  const { t } = useTranslation()
  
  return (
    <StyledPriceChart $isDark={isDark} $isExpanded={isChartExpanded} p="24px">
      <Flex justifyContent="center" alignItems="center" height="100%" flexDirection="column">
        <Text mb={['8px', '8px', '0px']} textAlign="center">
   
        You can swap W{tokens[tokenChainId]} for {tokens[tokenChainId]} (and vice versa) with no trading fees.;
        </Text>
        <Text mb={['8px', '8px', '0px']} textAlign="center">
          {t('Exchange rate is always 1 to 1.')}
        </Text>
      </Flex>
    </StyledPriceChart>
  )
}

export default BnbWbnbNotice
